<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :collapseHeight="isGift?'50':''" :toggleCollapseEnable="false">
    <el-form :model="form" ref="logFilterForm" label-width="70px" size="medium" class="filter-from">
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item" v-if="!isGift">
        <el-form-item label="用户名称：" prop="nickname">
          <el-input v-model="form.nickname" placeholder="请输用户名称"></el-input>
        </el-form-item>
        <el-form-item label="会内职务：" prop="position_ids">
          <el-cascader v-model="form.position_ids" :options="jobList" :props="props" placeholder="架构/会内职务">
          </el-cascader>
        </el-form-item>
        <el-form-item label="手机号：" prop="phone">
          <el-input v-model="form.phone" placeholder="手机号搜索"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item" v-if="!isGift">
        <DatePeriodPicker label="领取时间：" start-prop="create_start_time" :start-time.sync="form.create_start_time"
          end-prop="create_end_time" :endTime.sync="form.create_end_time" />
      </div>
      <div class="filter-item" v-if="isGift">
        <el-form-item label="用户名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输用户名称"></el-input>
        </el-form-item>
        <el-form-item label="会内职务：" prop="organization_id">
          <el-cascader v-model="form.organization_id" :options="jobList" :props="props" placeholder="架构/会内职务">
          </el-cascader>
        </el-form-item>
        <el-form-item label="手机号：" prop="phone">
          <el-input v-model="form.phone" placeholder="请输手机号"></el-input>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
// import { reachtypeArr, msgTypeArr, statusArray, usersCountArray } from '../data/default'
export default {
  name: "gift-list-filter",
  model: {
    prop: "filterForm",
    event: "updateFilter",
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    jobList: {
      type: Array
    },
    isGift: {
      type: Boolean,
    },
  },
  data() {
    return {
      rules: {},
      props: {
        value: "id",
        label: "name",
        expandTrigger: "hover",
        children: "position",
      },
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {
  },
  computed: {
    form: {
      get() {
        console.log(this.filterForm);
        return this.filterForm;
      },
      set(val) {
        this.$emit("updateFilter", val);
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
<style lang="scss" scoped>
::v-deep.el-input {
  width: auto;
}
</style>
