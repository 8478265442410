import { render, staticRenderFns } from "./GiftDadgeDialog.vue?vue&type=template&id=73713008&scoped=true&"
import script from "./GiftDadgeDialog.vue?vue&type=script&lang=js&"
export * from "./GiftDadgeDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73713008",
  null
  
)

export default component.exports