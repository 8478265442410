<template>
    <el-dialog :title="isGift ? '赠送勋章' : '已领取此勋章用户'" width="900px" @open="init" :visible.sync="openDialog">
        <div class="list-page">
            <list-filter v-model="filterForm"  :uploadFilter="ok" :isGift="isGift" :jobList="jobList" />
            <div class="list-content" v-loading="loading">
                <el-table :data="tbody" class="thead-light" stripe style="width: 100%;" height="400">
                    <!-- 操作 -->
                    <el-table-column label="操作" width="180" fixed="right">
                        <template slot-scope="scope">
                            <el-button-group>
                                <el-button type="text" size="small" @click="Details(scope.row)">
                                    查看详情
                                </el-button>
                                <el-button v-if="isGift" type="text" size="small" @click="Reward(scope.row)">
                                    赠送勋章
                                </el-button>
                                <el-button v-else type="text" size="small" @click="Delete(scope.row)">
                                    撤销勋章
                                </el-button>
                            </el-button-group>
                        </template>
                    </el-table-column>
                    <template v-for="(th, index) in isGift ? thead2 : thead">
                        <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
                            :min-width="th.minWidth || ''" :sortable="th.sortable" :key="index">
                        </el-table-column>
                    </template>
                </el-table>
            </div>
            <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
        </div>
    </el-dialog>
</template>
  
<script>
import listFilter from "./GiftDadge/ListFilter.vue";
import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
import Pagination from "@/base/components/Default/Pagination";
import ListImage from "@/base/components/List/ListImage";
import ListStatusText from "@/base/components/List/ListStatusText";
import { badgeDadgeUsers, SearchMemberList, positionLevel, badgeDadgeUsersReward, badgeDadgeUsersDelete } from '../api/index'
export default {
    name: 'GiftDadgeDialog',
    model: {
        prop: "open",
        event: "closeDialog",
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        isGift: {
            type: Boolean,
        },
        id: {
            type: [String, Number],
            default: "",
        },
        reflashList: {
            type: Function
        }
    },
    data() {
        return {
            loading: false, //加载
            thead: [
                { label: "ID", prop: "user_id", minWidth: 90 },
                { label: "用户", prop: "nickname", minWidth: 110 },
                { label: "手机号", prop: "phone", minWidth: 90, },
                {
                    label: "会内职务",
                    prop: "title_name",
                    minWidth: 80,
                },
                {
                    label: "领取时间",
                    prop: "create_time",
                    minWidth: 180,
                    sortable: true,
                },
            ],
            thead2: [
                { label: "ID", prop: "user_id", minWidth: 90 },
                { label: "用户", prop: "nickname", minWidth: 110 },
                { label: "手机号", prop: "phone", minWidth: 90, },
                {
                    label: "会内职务",
                    prop: "title_name",
                    minWidth: 80,
                },

            ],
            //筛选对象
            filterForm1: {
                create_start_time: -1, //开始时间
                create_end_time: -1, //结束时间
                nickname: '',
                phone: '',
                position_ids: [],
                page_size: 15, //每页数据量
            },
            filterForm2: {
                hash_user_id: 1,
                organization_id: [],
                phone:'',
                position_id: "",
                keyword: "", //关键词
                page_size: 10, //每页多少条数据
                page: 1,
            },
            // 职务列表
            jobList: [],
            pageData: {
                page_size: 15,
            },
            tbody: [],

        };
    },

    methods: {
        // 获取勋章列表
        getDadgeIndex(pageData) {
            this.tbody = [];
            this.pageData = {};
            this.loading = true;
            if (this.isGift) {
                let myPageData = JSON.parse(JSON.stringify(pageData))
                if (pageData.organization_id.length == 2) {
                    myPageData.organization_id = pageData.organization_id[0]
                    myPageData.position_ids = pageData.organization_id[1]
                } else {
                    myPageData.organization_id = -1
                    myPageData.position_ids = -1
                }

                SearchMemberList(myPageData).then(res => {
                    const { data } = res;
                    data.data.forEach((item) => {
                        item.is_show = item.is_show ? true : false;
                    });
                    this.tbody = data.data;
                    this.pageData = data;
                    this.loading = false;
                }).catch((err) => {
                    this.loading = false;
                });
            } else {

                badgeDadgeUsers({ ...pageData, badge_id: this.id }).then(res => {
                    const { data } = res;
                    data.data.forEach((item) => {
                        item.is_show = item.is_show ? true : false;
                    });
                    this.tbody = data.data;
                    this.pageData = data;
                    this.loading = false;
                }).catch((err) => {
                    this.loading = false;
                });
            }
        },
        //  赠送勋章
        Reward(row) {
            this.loading = true
            badgeDadgeUsersReward({ user_id: row.id, badge_id: this.id }).then(res => {
                this.$message.success("赠送成功");
                this.reflashList()
                this.reflashList2()
            }).catch(err => {
                this.loading = false
            })
        },
        //撤销勋章
        Delete(row) {
            this.$msgbox
          .confirm(`确定要将${row.nickname}的勋章撤销吗？`, "提示", {
            type: "error",
          })
          .then((res) => {
            this.loading = true
            badgeDadgeUsersDelete({ id: row.id }).then(res => {
                this.$message.success("撤销成功");
                this.reflashList()
                this.reflashList2()
            }).catch(err => {
                this.loading = false
            })
        })
        },
        // 筛选回调
        ok(e) {
            const pageData = {};
            this.filterForm = { ...this.filterForm, ...e, page: 1 };
            this.getDadgeIndex({ ...this.filterForm, ...pageData });
        },
        // 刷新列表
        reflashList2() {
            this.getDadgeIndex({
                page: this.pageData.current_page,
                ...this.filterForm,
            });
        },
        // 分页查询
        changPage(e) {
            this.filterForm.page_size = e.page_size;
            const pageData = { ...this.filterForm, ...e };
            this.getDadgeIndex(pageData);
        },
        Details(row) {
            let paramsData = {
                id: row.user_id,
                type: "formal",
                status: 1,
            };
            console.log(row.user_id);
            const { href } = this.$router.resolve({
                name: 'EditMember', params: {
                    data: this.$enCode(paramsData)
                }
            })
            window.open(href, '_blank');
        },
        init() {
            positionLevel({ is_list_tree: 0, is_show_all: 1 }).then(res => {
                this.jobList = res.data
            })
            this.getDadgeIndex(this.filterForm)
        }
    },

    computed: {
        openDialog: {
            get() {
                return this.open;
            },
            set(val) {
                this.$emit("closeDialog", val);
            },
        },
        filterForm: {
            get() {
                return this.isGift ? this.filterForm2 : this.filterForm1
            },
            set(val) {
                if (this.isGift) {
                    this.filterForm2 = val
                } else {
                    this.filterForm1 = val
                }
            },
        }
    },
    components: { listFilter, Pagination, PopoverInputEdit, ListImage, ListStatusText },

};
</script>
  
<style lang="scss" scoped></style>
  