<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="true">
    <el-form :model="form" ref="logFilterForm" label-width="70px" size="medium" class="filter-from">

      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="勋章名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输勋章名称"></el-input>
        </el-form-item>
        <el-form-item label="达成条件：" prop="reach_type">
          <el-select v-model="form.reach_type" placeholder="请选择达成条件">
            <el-option v-for="op in reachtypeArr" :key="op.id" :label="op.name" :value="op.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：" prop="status">
          <el-select v-model="form.status" placeholder="请选择状态">
            <el-option v-for="op in  statusArray" :key="op.id" :label="op.name" :value="op.id"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="推送渠道：" prop="msg_type">
          <el-select v-model="form.msg_type" placeholder="请选择推送渠道">
            <el-option v-for="op in msgTypeArr" :key="op.id" :label="op.name" :value="op.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="领取人数：" prop="name">
          <el-select v-model="form.users_count" @change="usersCountChang" placeholder="请选择领取人数">
            <el-option v-for="op in usersCountArray" :key="op.id" :label="op.name" :value="op.id"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">

        <DatePeriodPicker label="创建时间：" start-prop="create_start_time" :start-time.sync="form.create_start_time"
          end-prop="create_end_time" :endTime.sync="form.create_end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { reachtypeArr, msgTypeArr, statusArray, usersCountArray } from '../data/default'
export default {
  name: "badge-list-filter",
  model: {
    prop: "filterForm",
    event: "updateFilter",
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      reachtypeArr:  [{ name: "全部", id: -1 },...reachtypeArr],
      msgTypeArr,
      statusArray:[  { name: "全部", id: -1 },...statusArray],
      usersCountArray
    };
  },
  methods: {
    usersCountChang(id) {


      switch (id) {
        case -1:
          this.form.users_count_min = -1
          this.form.users_count_max = -1
          break;
        case 3:
          this.form.users_count_min = 200
          this.form.users_count_max = -1
          break;
        default:
          this.usersCountArray.forEach(v => {
            if (v.id === id) {
              this.form.users_count_min = +v.name.split('~')[0]
              this.form.users_count_max = +v.name.split('~')[1]
            }
          })
          break;
      }


      console.log(this.form);
    },
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() { },
  computed: {
    form: {
      get() {
        console.log(this.filterForm);
        return this.filterForm;
      },
      set(val) {
        this.$emit("updateFilter", val);
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
<style lang="scss" scoped>
::v-deep.el-input {
  width: auto;
}
</style>
